import React, { Component, Fragment } from "react"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import Collapse from "@mui/material/Collapse"
import Typography from "@mui/material/Typography"
import ExpandMore from "@mui/icons-material/ExpandMore"
import tinyColor from "tinycolor2"
import { withTranslation } from "react-i18next"
import { unitConversion } from "@igloocloud/igloosharedui"

export const Keyframes = (props) => {
  const toCss = (cssObject) =>
    typeof cssObject === "string"
      ? cssObject
      : Object.keys(cssObject).reduce((accumulator, key) => {
          const cssKey = key.replace(/[A-Z]/g, (v) => `-${v.toLowerCase()}`)
          const cssValue = cssObject[key].toString().replace("'", "")
          return `${accumulator}${cssKey}:${cssValue};`
        }, "")

  return (
    <style>
      {`@keyframes ${props.name} {
        ${Object.keys(props)
          .map((key) => {
            return ["from", "to"].includes(key)
              ? `${key} { ${toCss(props[key])} }`
              : /^_[0-9]+$/.test(key)
              ? `${key.replace("_", "")}% { ${toCss(props[key])} }`
              : ""
          })
          .join(" ")}
      }`}
    </style>
  )
}

export default withTranslation()(
  class SeriesList extends Component {
    state = { fetchMoreLoading: false }

    queryMore = async () => {
      if (
        !this.queryMore.locked &&
        this.props.seriesData.user.floatSeriesVariableCount >
          this.props.seriesData.user.floatSeriesVariables.length
      ) {
        this.queryMore.locked = true

        try {
          this.setState({ fetchMoreLoading: true })

          await this.props.seriesData.fetchMore({
            variables: {
              offset: this.props.seriesData.user.floatSeriesVariables.length,
              limit:
                this.props.seriesData.user.floatSeriesVariableCount -
                  this.props.seriesData.user.floatSeriesVariables.length >=
                20
                  ? 20
                  : this.props.seriesData.user.floatSeriesVariableCount % 20,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev
              }

              const newFloatSeriesVariables = [
                ...prev.user.floatSeriesVariables,
                ...fetchMoreResult.user.floatSeriesVariables,
              ]

              return {
                user: {
                  ...prev.user,
                  floatSeriesVariables: newFloatSeriesVariables,
                },
              }
            },
          })
        } finally {
          this.setState(() => {
            this.queryMore.locked = false

            return { fetchMoreLoading: false }
          })

          this.checkIfScrollable()
        }
      }
    }

    checkIfScrollable = () => {
      if (
        this.seriesList &&
        this.seriesList.clientHeight > this.seriesList.scrollHeight
      ) {
        this.queryMore()
      }
    }

    componentDidMount() {
      this.checkIfScrollable()

      window.addEventListener("resize", this.checkIfScrollable)
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.checkIfScrollable)
    }

    render() {
      const {
        loadingVariables,
        selectedVariables,
        setSelectedVariables,
        expandedThings,
        setExpandedThings,
        allSeries,
        shownUnits,
        isInDrawer,
        fetchSeries,
        t,
        user,
        seriesUser: { floatSeriesVariables },
      } = this.props
      const {
        REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
        REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
        REACT_APP_SERIES_COLORS,
      } = process.env
      const seriesColors = JSON.parse(REACT_APP_SERIES_COLORS)

      const things = floatSeriesVariables.reduce(
        (things, variable) => [
          ...things.filter(
            ({ id: idToRemove }) => idToRemove !== variable.thing.id
          ),
          things.find(({ id: idToFind }) => idToFind === variable.thing.id)
            ? {
                ...things.find(
                  ({ id: idToFind }) => idToFind === variable.thing.id
                ),
                variables: [
                  ...things.find(
                    ({ id: idToFind }) => idToFind === variable.thing.id
                  ).variables,
                  variable,
                ],
              }
            : {
                ...variable.thing,
                variables: [variable],
              },
        ],
        []
      )

      const loadingUnits = [
        ...new Set(
          floatSeriesVariables
            .filter(({ id }) => loadingVariables.includes(id))
            .map(({ unitOfMeasurement }) => unitOfMeasurement)
        ),
      ]

      return things[0] ? (
        <List
          style={{
            padding: isInDrawer ? 0 : "0 16px",
            height: isInDrawer ? "100%" : "calc(100% - 88px)",
            overflow: "auto",
          }}
          ref={(ref) => (this.seriesList = ref)}
          onScroll={(event) => {
            if (
              event.target.scrollTop + event.target.clientHeight >=
              event.target.scrollHeight - 600
            ) {
              this.queryMore()
            }
          }}
        >
          {things.map(({ id: thingId, name: thingName, variables }) => (
            <Fragment key={"data-lab-thing-" + thingId}>
              <ListItem
                button
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  borderRadius: isInDrawer ? 0 : "4px",
                  margin: isInDrawer ? 0 : "4px 0",
                }}
                onClick={() =>
                  setExpandedThings(({ expandedThings }) => ({
                    expandedThings: expandedThings.includes(thingId)
                      ? expandedThings.filter((id) => id !== thingId)
                      : [...expandedThings, thingId],
                  }))
                }
              >
                <ListItemText
                  disableTypography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {thingName}
                </ListItemText>
                <ExpandMore
                  style={{
                    transform: expandedThings.includes(thingId)
                      ? "rotate(180deg)"
                      : "",
                    transition:
                      "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  }}
                />
              </ListItem>
              {variables.map(
                ({
                  id: variableId,
                  name: variableName,
                  unitOfMeasurement,
                  nodeCount,
                }) => (
                  <Collapse in={expandedThings.includes(thingId)}>
                    <Keyframes
                      name={"pulse-" + variableId}
                      _0={{
                        backgroundColor: selectedVariables.includes(variableId)
                          ? tinyColor(
                              selectedVariables.findIndex(
                                (id) => id === variableId
                              ) !== -1
                                ? seriesColors[
                                    selectedVariables.findIndex(
                                      (id) => id === variableId
                                    ) % seriesColors.length
                                  ]
                                : seriesColors[
                                    (selectedVariables.length - 1) %
                                      seriesColors.length
                                  ]
                            ).setAlpha(0.15)
                          : "unset",
                      }}
                      _50={{
                        backgroundColor: selectedVariables.includes(variableId)
                          ? tinyColor(
                              selectedVariables.findIndex(
                                (id) => id === variableId
                              ) !== -1
                                ? seriesColors[
                                    selectedVariables.findIndex(
                                      (id) => id === variableId
                                    ) % seriesColors.length
                                  ]
                                : seriesColors[
                                    (selectedVariables.length - 1) %
                                      seriesColors.length
                                  ]
                            ).setAlpha(0.05)
                          : "unset",
                      }}
                      _100={{
                        backgroundColor: selectedVariables.includes(variableId)
                          ? tinyColor(
                              selectedVariables.findIndex(
                                (id) => id === variableId
                              ) !== -1
                                ? seriesColors[
                                    selectedVariables.findIndex(
                                      (id) => id === variableId
                                    ) % seriesColors.length
                                  ]
                                : seriesColors[
                                    (selectedVariables.length - 1) %
                                      seriesColors.length
                                  ]
                            ).setAlpha(0.15)
                          : "unset",
                      }}
                    />
                    <List component="div" disablePadding>
                      <ListItem
                        key={"data-lab-variable-" + variableId}
                        button
                        onClick={() => {
                          if (selectedVariables.includes(variableId)) {
                            localStorage.setItem(
                              "dataLabDates",
                              localStorage.getItem("dataLabDates") &&
                                JSON.parse(
                                  localStorage.getItem("dataLabDates")
                                )[0]
                                ? JSON.stringify([
                                    ...JSON.parse(
                                      localStorage.getItem("dataLabDates")
                                    ).filter(
                                      ({ id }) =>
                                        id !== localStorage.getItem("userId")
                                    ),
                                    {
                                      id: localStorage.getItem("userId"),
                                      startDate:
                                        JSON.parse(
                                          localStorage.getItem("dataLabDates")
                                        ).find(
                                          ({ id }) =>
                                            id ===
                                            localStorage.getItem("userId")
                                        )?.startDate || null,
                                      endDate:
                                        JSON.parse(
                                          localStorage.getItem("dataLabDates")
                                        ).find(
                                          ({ id }) =>
                                            id ===
                                            localStorage.getItem("userId")
                                        )?.endDate || null,
                                      selectedVariables:
                                        selectedVariables.filter(
                                          (selectedVariableId) =>
                                            selectedVariableId !== variableId
                                        ),
                                    },
                                  ])
                                : JSON.stringify([
                                    {
                                      id: localStorage.getItem("userId"),
                                      selectedVariables:
                                        selectedVariables.filter(
                                          (selectedVariableId) =>
                                            selectedVariableId !== variableId
                                        ),
                                    },
                                  ])
                            )

                            setSelectedVariables(({ selectedVariables }) => ({
                              selectedVariables: selectedVariables.filter(
                                (selectedVariableId) =>
                                  selectedVariableId !== variableId
                              ),
                            }))
                          } else {
                            localStorage.setItem(
                              "dataLabDates",
                              localStorage.getItem("dataLabDates") &&
                                JSON.parse(
                                  localStorage.getItem("dataLabDates")
                                )[0]
                                ? JSON.stringify([
                                    ...JSON.parse(
                                      localStorage.getItem("dataLabDates")
                                    ).filter(
                                      ({ id }) =>
                                        id !== localStorage.getItem("userId")
                                    ),
                                    {
                                      id: localStorage.getItem("userId"),
                                      startDate:
                                        JSON.parse(
                                          localStorage.getItem("dataLabDates")
                                        ).find(
                                          ({ id }) =>
                                            id ===
                                            localStorage.getItem("userId")
                                        )?.startDate || null,
                                      endDate:
                                        JSON.parse(
                                          localStorage.getItem("dataLabDates")
                                        ).find(
                                          ({ id }) =>
                                            id ===
                                            localStorage.getItem("userId")
                                        )?.endDate || null,
                                      selectedVariables: [
                                        ...selectedVariables,
                                        variableId,
                                      ],
                                    },
                                  ])
                                : JSON.stringify([
                                    {
                                      id: localStorage.getItem("userId"),
                                      selectedVariables: [
                                        ...selectedVariables,
                                        variableId,
                                      ],
                                    },
                                  ])
                            )

                            setSelectedVariables(({ selectedVariables }) => ({
                              selectedVariables: [
                                ...selectedVariables,
                                variableId,
                              ],
                            }))

                            if (
                              !allSeries.find(
                                (series) => series.id === variableId
                              )
                            ) {
                              fetchSeries(variableId)
                            }
                          }
                        }}
                        selected={selectedVariables.includes(variableId)}
                        style={{
                          animationName: "pulse-" + variableId,
                          animationDuration: "1.5s",
                          animationIterationCount: loadingVariables.includes(
                            variableId
                          )
                            ? "infinite"
                            : 0,
                          paddingLeft: "32px",
                          borderRadius: isInDrawer ? 0 : "4px",
                          margin: isInDrawer ? 0 : "4px 0",
                          color: selectedVariables.includes(variableId)
                            ? tinyColor(
                                selectedVariables.findIndex(
                                  (id) => id === variableId
                                ) !== -1
                                  ? seriesColors[
                                      selectedVariables.findIndex(
                                        (id) => id === variableId
                                      ) % seriesColors.length
                                    ]
                                  : seriesColors[
                                      (selectedVariables.length - 1) %
                                        seriesColors.length
                                    ]
                              ).setAlpha(0.15)
                            : null,
                          backgroundColor: selectedVariables.includes(
                            variableId
                          )
                            ? tinyColor(
                                selectedVariables.findIndex(
                                  (id) => id === variableId
                                ) !== -1
                                  ? seriesColors[
                                      selectedVariables.findIndex(
                                        (id) => id === variableId
                                      ) % seriesColors.length
                                    ]
                                  : seriesColors[
                                      (selectedVariables.length - 1) %
                                        seriesColors.length
                                    ]
                              ).setAlpha(0.15)
                            : "unset",
                        }}
                        disabled={
                          (shownUnits.length + loadingUnits.length >= 2 &&
                            !(
                              shownUnits.includes(unitOfMeasurement) ||
                              loadingUnits.includes(unitOfMeasurement)
                            )) ||
                          nodeCount === 0
                        }
                      >
                        <ListItemText
                          disableTypography
                          style={{
                            color: selectedVariables.includes(variableId)
                              ? tinyColor(
                                  selectedVariables.findIndex(
                                    (id) => id === variableId
                                  ) !== -1
                                    ? seriesColors[
                                        selectedVariables.findIndex(
                                          (id) => id === variableId
                                        ) % seriesColors.length
                                      ]
                                    : seriesColors[
                                        (selectedVariables.length - 1) %
                                          seriesColors.length
                                      ]
                                ).darken()
                              : textColor,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {variableName}
                        </ListItemText>
                        <ListItemSecondaryAction
                          style={{
                            color: selectedVariables.includes(variableId)
                              ? tinyColor(
                                  selectedVariables.findIndex(
                                    (id) => id === variableId
                                  ) !== -1
                                    ? seriesColors[
                                        selectedVariables.findIndex(
                                          (id) => id === variableId
                                        ) % seriesColors.length
                                      ]
                                    : seriesColors[
                                        (selectedVariables.length - 1) %
                                          seriesColors.length
                                      ]
                                ).darken()
                              : tinyColor(textColor).setAlpha(0.38),
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            zIndex: -1,
                          }}
                          className="notSelectable"
                        >
                          {unitConversion(
                            null,
                            unitOfMeasurement,
                            user.lengthAndMass,
                            user.temperature
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Collapse>
                )
              )}
            </Fragment>
          ))}
        </List>
      ) : (
        <Typography
          variant="h5"
          className="notSelectable defaultCursor"
          style={{
            textAlign: "center",
            margin: "32px",
            color: textOnBackgroundColor,
          }}
        >
          {t`No series`}
        </Typography>
      )
    }
  }
)
